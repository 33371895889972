import { useDisclosure, useToast } from '@mybridge/ui/hooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  fetchComments,
  fetchDashboardComment,
} from 'packages/mybridgev4/store/actions/comment.actions';
import {
  blockConnection,
  getAllConnectionIDs,
  requestConnection,
  requestFollow,
  unfollow,
  withdrawConnection,
} from 'packages/mybridgev4/store/actions/connection.actions';
import {
  acceptEvent,
  ignoreEvent,
  ignorePost,
  pinPost,
  postDelete,
  postRepost,
  triggerFetchPosts,
  unpinPost,
  blockReportPost,
} from 'packages/mybridgev4/store/actions/post.actions';
import { createContext, useEffect, useMemo, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPostFunc, postDisLikeFunc, postLikeFunc } from 'v4/lib/api/post';
import { getUserFullName } from 'v4/lib/commons';
import { MyNetworkContext } from 'v4/components/network/context';
import _ from 'lodash';
import {
  updateCampaignPerformanceReport,
  updatecurrentPerformanceData,
} from 'v4/store/actions/campaign.action';
import { createMatrixRoom } from 'v4/store/actions/chat.actions';
import { useRouter } from 'next/router';
export const PostBoxContext = createContext({});

export const usePostBoxContext = (props) => {
  const {
    post,
    initialFetchEnabled = false,
    initialFetchComments = false,
  } = props ?? {};
  const router=useRouter()
  const { deletedPosts } = useSelector((state) => state.posts ?? {});
  const { userProfileInfo, currentProfileCommentData } = useSelector(
    (state) => state.userProfile
  );
  const { allConnectionIDs } = useSelector((state) => state.connection);
  const { currentPerformance } = useSelector((state) => state.campaignReducer);
  const { loggedIn } = useSelector((state) => state.user);
  const [commentsState, setCommentsState] = useState();
  const [commentModal, setCommentModal] = useState(false);
  const [comments, setComments] = useState([]);
  const [dashboardComment, setDashboardComment] = useState([]);
  const [following, setfollowing] = useState(false);
  const [handshaked, setHandshaked] = useState(false);
  const [inCircle, setInCircle] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [isCompanyComment, setIsCompanyComment] = useState(false);
  const [postState, setPostState] = useState(post?.repost ?? post);
  const [currentPostCommenter, setCurrentPostCommenter] = useState(null);
  const [errorExistCompany,setErrorExistCompany]=useState(true);
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const toast = useToast({position: 'top'});
  const notInterestedModal = useDisclosure();
  const blockReportModal = useDisclosure();
  const deletePostModal = useDisclosure();
  const pinPostModal = useDisclosure();
  const editPostModal = useDisclosure();
  const repostModal = useDisclosure();
  const quotePostModal = useDisclosure();

  const {
    requestsSent,
    refetchRequestsSent,
    requestsSentIsFetching,
    requestsSentIsLoading,
  } = useContext(MyNetworkContext);

  const { id: uid } = userProfileInfo ?? {};

  const { comment_count, reposts_by, like_count, dislike_count, id } =
    postState ?? {};
  const hasInsights = useMemo(() => {
    return (
      comment_count > 0 ||
      reposts_by?.length > 0 ||
      like_count > 0 ||
      dislike_count > 0
    );
  }, [postState]);

  // const getRepost = (post) => {
  //   const { gif, content, media } = post?.repost ?? {};
  //   const post_ = { ...post, content, gif, media };
  //   return post_;
  // };

  useEffect(() => {
    setPostState({ ...(post ?? {}) });
  }, [post]);

  useEffect(() => {
    setPinned(postState?.pinned ?? false);
  }, [postState]);

  const {
    followers = [],
    following: followings = [],
    circle,
    handshake_requests,
    handshake_received,
  } = allConnectionIDs ?? {};

  useEffect(() => {
    updateConnectionsStates();
  }, []);

  useEffect(() => {
    updateConnectionsStates();
  }, [
    postState,
    followers,
    followings,
    circle,
    handshake_requests,
    handshake_received,
  ]);

  const updateConnectionsStates = () => {
    const following_ =
      followers?.includes?.(postState?.author?.id) ||
      followings?.includes?.(postState?.author?.id);
    const inCircle_ = circle?.includes(postState?.author?.id);
    const handshaked_ =
      handshake_requests?.includes(postState?.author?.id) ||
      handshake_received?.includes(postState?.author?.id);

    setfollowing(following_);
    setHandshaked(handshaked_);
    setInCircle(inCircle_);
  };

  useEffect(() => {
    if (deletedPosts?.includes?.(postState?.id)) {
      setPostState({ ...postState, removed: true });
    }
  }, [deletedPosts]);

  const handleAdClicksRouting = async (type, postId, url) => {
    // console.log(type, postId, url);
    const getType = (type) => {
      switch (type) {
        case 'click':
          return 2;
        case 'reach':
          return 5;
        case 'impression':
          return 1;
        case 'video':
          return 4;
        case 'conversion':
          return 3;
        default:
          return 0;
      }
    };

    const payload = {
      id: postId,
      body: { analytic_type: getType(type) },
    };
    // const currentData = [100:{'click', 'reach', 'impression'}, 101:{'click', 'reach', 'impression'}];
    // if(currentPerformance?.)
    dispatch(updateCampaignPerformanceReport(payload));
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  // query for fetching comments
  const {
    refetch: refetchComments,
    isLoading: isLoadingComments,
    isFetching: isFetchingComments,
  } = useQuery(
    ['getComments', postState?.id],
    () => {
      return postState.id ? dispatch(fetchComments(postState)) : [];
    },
    {
      refetchOnWindowFocus: false,
      enabled: initialFetchComments,
      onSuccess: (data) => {
        setCommentsState(data?.payload);
        setComments([...(data?.payload?.results ?? [])]);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  // query for fetching dashboard comment
  const {
    refetch: refetchDashboardComment,
    isLoading: isLoadingDashboardComment,
    isFetching: isFetchingDashboardComment,
  } = useQuery(
    ['getDashboardComment', postState?.id],
    () => {
      return postState.id ? dispatch(fetchDashboardComment(postState)) : [];
    },
    {
      refetchOnWindowFocus: false,
      enabled: initialFetchComments,
      onSuccess: (data) => {
        setDashboardComment([...(data?.payload?.results ?? [])]);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  // query for fetching single post
  const { refetch, isLoading, isFetching } = useQuery(
    ['getPostDetail', postState?.id],
    getPostFunc,
    {
      refetchOnWindowFocus: false,
      enabled: initialFetchEnabled,
      onSuccess: (data) => {


        setTimeout(() => {
          setPostState({ ...(data ?? {}) });
          
        }, 2000);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  // query for deleting post
  const { mutate: deletePost, isLoading: deletePostLoading } = useMutation(
    (body) => dispatch(postDelete(body?.id?body:postState)),
    {
      onSuccess: (data) => {
        if (data.status_code >= 400) {
        } else {
          // refetch();
          dispatch(triggerFetchPosts(true));
          editPostModal?.onClose?.();
          setPostState({ ...postState, removed: true });
          toast({
            title: 'Post deleted successfully',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          deletePostModal?.onClose?.();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  // query for ignore post
  const { mutate: mutateIgnorePost, isLoading: ignorePostLoading } =
    useMutation((body) => dispatch(ignorePost(body)), {
      onSuccess: (data) => {
        if (data.status_code >= 400) {
        } else {
          toast({
            title: 'Post ignored successfully',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          setPostState({ ...postState, ignored: true });
          notInterestedModal?.onClose?.();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  // query for reposting a post
  const { mutateAsync: repostMutate, isLoading: repostLoading } = useMutation(
    (body) => dispatch(postRepost({ method: 'POST', id: body?.id })),
    {
      onSuccess: (data) => {
        if (data.status_code >= 400) {
        } else {
          toast({
            title: 'Post reposted successfully',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          dispatch(triggerFetchPosts(true));
          repostModal?.onClose?.();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  // query for pin post
  const { mutate: mutateUnpinPost, isLoading: unpinPostLoading } = useMutation(
    (body) => dispatch(unpinPost(body)),
    {
      onSuccess: (data) => {
        if (data.status_code >= 400) {
        } else {
          toast({
            title: 'Post unpinned successfully',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          setPinned(false);
          refetch?.();
          pinPostModal?.onClose?.();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  // Pin post mutation
  const { mutate: mutatePinPost, isLoading: pinPostLoading } = useMutation(
    (body) => dispatch(pinPost(body)),
    {
      onSuccess: (data) => {
        if (data.status_code >= 400) {
        } else {
          toast({
            title: 'Post pinned successfully',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          setPinned(true);
          refetch?.();
          pinPostModal?.onClose?.();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  // query for following author
  const { mutate: followAuthor, isLoading: followAuthorLoading } = useMutation(
    (body) => dispatch(requestFollow(body)),
    {
      onSuccess: (data) => {
        if (data.status_code >= 400) {
        } else {
          setfollowing(true);
          dispatch(getAllConnectionIDs());
          toast({
            title: 'Started following ' + getUserFullName(postState?.author),
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          props?.onClose?.();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  // query for un-following author
  const { mutate: unfollowAuthor, isLoading: unfollowAuthorLoading } =
    useMutation((body) => dispatch(unfollow(body)), {
      onSuccess: (data) => {
        if (data.status_code >= 400) {
        } else {
          setfollowing(false);
          dispatch(getAllConnectionIDs());
          toast({
            title: 'Stopped following ' + getUserFullName(postState?.author),
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          props?.onClose?.();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  // query for send handshake request
  const { mutate: handshake, isLoading: handshakeLoading } = useMutation(
    (body) => dispatch(requestConnection(body)),
    {
      onSuccess: (data) => {
        if (data.status_code >= 400) {
        } else {
          setHandshaked(true);
          dispatch(getAllConnectionIDs());
          toast({
            title: `Handshake request sent`,
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          props?.onClose?.();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  // query for withdrawing handshake
  const { mutate: revokeHandshake, isLoading: revokeHandshakeLoading } =
    useMutation((body) => dispatch(withdrawConnection(body)), {
      onSuccess: (data) => {
        if (data.status_code >= 400) {
        } else {
          setHandshaked(false);
          dispatch(getAllConnectionIDs());
          toast({
            title: 'Request has been withdrawn',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          props?.onClose?.();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  // query for blocking/reporting post
  const { mutate: blockReport, isLoading: blockReportLoading } = useMutation(
    (body) => dispatch(blockReportPost(body)),
    {
      onSuccess: (data) => {
        console.log('etmettttttttttttttttt',data)
        if (data.payload?.status_code >= 400) {
          toast({
            title: data?.payload?.non_field_errors[0]??'something went wrong',
            // title: 'Post has been reported and the user has been blocked',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top',
          });
          setErrorExistCompany(true)
        } else {
          setErrorExistCompany(false)
          refetch();
        }
      },
      onError: (error) => {
        setErrorExistCompany(true)
         toast({
            title: 'something went wrong',
            // title: 'Post has been reported and the user has been blocked',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top',
          });
      },
    }
  );

  // query for liking post
  const { mutate: like, isLoading: likeLoading } = useMutation(postLikeFunc, {
    onSuccess: (data) => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // query for disliking post
  const { mutate: dislike, isLoading: dislikeLoading } = useMutation(
    postDisLikeFunc,
    {
      onSuccess: (data) => {
        refetch();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  //accept event
  const { mutateAsync: acceptEventAsync, isLoading: acceptEventLoading } =
    useMutation({
      mutationKey: ['accept-event'],
      mutationFn: async (payload) => {
        const resp = (await dispatch(acceptEvent(payload)))?.payload;
        return resp;
      },
    });

  //ignore event
  const { mutateAsync: ignoreEventAsync, isLoading: ignoreEventLoading } =
    useMutation({
      mutationKey: ['ignore-event'],
      mutationFn: async (payload) => {
        const resp = (await dispatch(ignoreEvent(payload)))?.payload;
        return resp;
      },
    });

  const acceptEvent_ = async (event) => {
    try {
      const resp = await acceptEventAsync(event?.id);
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Error occured while accepting event invite',
        });
      } else {
        toast({
          title: 'Success',
          status: 'success',
          description: `Event invite accepted successfully`,
          position:'top',
        });
        setPostState(resp);
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const ignoreEvent_ = async (event) => {
    try {
      const resp = await ignoreEventAsync(event?.id);
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Error occured while accepting event invite',
        });
      } else {
        toast({
          title: 'Success',
          status: 'success',
          description: `Event invite accepted successfully`,
          position:'top',
        });
        setPostState(resp);
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const handleLike = () => {
    const isUserLiked = postState?.liked_by?.find((el) => el.id === uid);
    handleAdClicksRouting('click', id);
    if (isUserLiked) {
      like({
        id,
        remove: true,
        ...(currentProfileCommentData
          ? { page: currentProfileCommentData?.id }
          : {}),
      });
    } else {
      like({
        id,
        remove: false,
        ...(currentProfileCommentData
          ? { page: currentProfileCommentData?.id }
          : {}),
      });
    }
  };

  const handleDislike = () => {
    const isUserDisliked = postState?.dislike_by?.find((el) => el.id === uid);
    handleAdClicksRouting('click', id);
    if (isUserDisliked) {
      dislike({
        id,
        remove: true,
        ...(currentProfileCommentData
          ? { page: currentProfileCommentData?.id }
          : {}),
      });
    } else {
      dislike({
        id,
        remove: false,
        ...(currentProfileCommentData
          ? { page: currentProfileCommentData?.id }
          : {}),
      });
    }
  };

  const handleFollow = () => {
    if (following) {
      unfollowAuthor({
        follow_to: postState?.author?.id,
      });
    } else {
      followAuthor({
        follow_to: postState?.author?.id,
      });
    }
  };

  const handleHandshake = async (inCircle="") => {

if(inCircle!=="Mutual Connection"){
  refetchRequestsSent?.();

  if (handshaked) {
    const request_id = _.find(
      requestsSent,
      (request) => request?.user_info?.id === postState?.author?.id
    );
    // console.log('request_id', request_id);
    revokeHandshake({
      connection_request_id: request_id?.id,
    });
  } else {
    try {
      const payload = {
        creation_content: {
          'm.federate': true,
        },
        name: `${postState?.author?.first_name},${userProfileInfo?.first_name}`,
        preset: 'trusted_private_chat',
        invite: [postState?.author?.matrix_user_id],
        is_direct: true,
        //   initial_state: [
        //     {
        //         type: "m.room.encryption",
        //         state_key: "",
        //         content: {
        //             algorithm: "m.megolm.v1.aes-sha2"
        //         }
        //     }
        // ]
      };
      const createRoom = await dispatch(createMatrixRoom(payload));
      const resp = await handshake({
        to_user_id: postState?.author?.id,
        chat_room_id: createRoom?.payload?.room_id
          ? createRoom?.payload?.room_id
          : null,
      });

      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      }
    } catch (e) {
      toast({
        title: 'Error while sending connection request!',
        description: e?.message,
        status: 'error',
      });
    }
  }  
}
else{
  router?.push(`/profile/network/${postState?.author?.slug}`)
  }
  };

  const pinUnpinPost = () => {
    if (pinned) {
      mutateUnpinPost({
        id: postState?.id,
      });
    } else {
      mutatePinPost({
        id: postState?.id,
      });
    }
  };

  const repostPost = async () => {
    await repostMutate({
      id: postState?.id,
    });
  };

  const { gif, media } = postState ?? {};

  const getLocalStorage =
    typeof window !== 'undefined' && localStorage.getItem('commentCLicked');
  const hasMedia = !getLocalStorage && (gif?.length > 0 || media?.length > 0);

  const commentsAllowed = useMemo(() => {
    const { circle, followers, following } = allConnectionIDs ?? {};
    if (
      postState?.reply_control === 0 ||
      userProfileInfo?.id === postState?.author?.id
    ) {
      return true;
    }
    const inCircle = circle?.includes(post?.author?.id);
    const inFollowed =
      followers?.includes(post?.author?.id) ||
      following?.includes(post?.author?.id);

    if (postState?.reply_control === 1) {
      return inCircle;
    } else if (postState?.reply_control === 2) {
      return inFollowed || inCircle;
    }
  }, [postState, allConnectionIDs, userProfileInfo]);

  // return the state
  return {
    ...props,
    userProfileInfo,
    loggedIn,
    post: postState,
    hasInsights,
    handleDislike,
    handleLike,
    refetch,
    likeLoading,
    dislikeLoading,
    isLoading: isLoading || isFetching,
    hasMedia,
    comments,
    commentsState,
    setComments,
    dashboardComment,
    refetchDashboardComment,
    isLoadingDashboardComment,
    isFetchingDashboardComment,
    refetchComments,
    isFetchingComments,
    isLoadingComments,
    commentsAllowed,
    blockReportLoading,
    blockReport,
    errorExistCompany,
    ignorePostLoading,
    ignorePost: mutateIgnorePost,
    following,
    handleFollow,
    followLoading: followAuthorLoading || unfollowAuthorLoading,
    handshaked,
    handleHandshake,
    handshakeLoading: handshakeLoading || revokeHandshakeLoading,
    inCircle,
    notInterestedModal,
    blockReportModal,
    deletePostModal,
    deletePost,
    deletePostLoading,
    pinUnpinPost,
    pinUnpinPostLoading: pinPostLoading || unpinPostLoading,
    pinPostModal,
    pinned,
    editPostModal,
    repostModal,
    repostPost,
    repostLoading,
    quotePostModal,
    acceptEvent: acceptEvent_,
    acceptEventAsync,
    acceptEventLoading,
    ignoreEvent: ignoreEvent_,
    ignoreEventAsync,
    ignoreEventLoading,
    requestsSent,
    commentModal,
    setCommentModal,
    handleAdClicksRouting,
    isCompanyComment,
    setIsCompanyComment,
    currentPostCommenter,
    setCurrentPostCommenter,
  };
};
